<template>
  <span>
    <v-row dense justify="center" class="mt-5">
      <v-col
        v-for="indicador in listaKpisCardInicial" 
        :key="indicador"
        cols="5" sm="3" md="auto"
        class="flex-md-grow-1"
      >
        <v-card class="my-1">
          <v-card-title>
            <v-tooltip bottom v-if="traducaoIndicadores[indicador] != null">
              <template v-slot:activator="{ on }">
                <h5 v-on="on">{{ indicador | toUpperCase }}</h5>
              </template>
              <span >{{ traducaoIndicadores[indicador] }}</span>
            </v-tooltip>
            <h5 v-else >{{ indicador | toUpperCase }}</h5>            <status-evolucao-kpis
              class="ml-1"
              :valorAtual="dadosKpisMunicipioSelecionado == null ? 0 : dadosKpisMunicipioSelecionado[indicador] || 0"
              :valorAnterior="dadosKpisMunicipioSelecionadoCompetenciaAnterior == null ? 0 : dadosKpisMunicipioSelecionadoCompetenciaAnterior[indicador] || 0"
            />
          </v-card-title>
          <v-card-text>
            <div class="display-3">
              {{ dadosKpisMunicipioSelecionado == null ? 0 : dadosKpisMunicipioSelecionado[indicador] | parseNumberToFloatBrIgnoringNaN }} 
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row dense justify="center">
      <v-col
        cols="12" md="8"
        class="flex-md-grow-1"
      >
        <v-card class="my-1">
          <v-card-text>
            <dashboard-faturamento-visao-municipios-kpis-data-table  
              :dadosKpisByMunicipio="dadosKpis"
              :traducaoIndicadores="traducaoIndicadores"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12" md="auto"
        class="flex-md-grow-1"
      >
        <v-card class="my-1">
          <v-card-text>
            <dashboard-faturamento-visao-municipios-kpis-map 
              :dadosKpis="dadosKpis"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <dashboard-faturamento-visao-municipios-cards-kpis-por-municipio 
      :dados="dadosKpis"
      :metasKpis="metasKpis"
      :municipios="dadosKpis.map(dado => dado.nome_municipio)"
      :tipoOrdenacao="tipoOrdenacao"
      :kpiOrdenacao="kpiOrdenacao"
      :exportingOptions="exportingOptions"

    />
  </span>
</template>

<script>

import DashboardFaturamentoService from "@/services/DashboardFaturamentoService";

import Highcharts from 'highcharts';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
HighchartsNoData(Highcharts);
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
exporting(Highcharts);
exportData(Highcharts);
import dayjs from 'dayjs';

export default {
  name: "DashboardFaturamentoVisaoMunicipios",
  components: {
    StatusEvolucaoKpis: () => import("@/components/dashboardLno/StatusEvolucaoKpis.vue"),
    DashboardFaturamentoVisaoMunicipiosKpisDataTable: () => import("@/components/dashboardFaturamento/DashboardFaturamentoVisaoMunicipiosKpisDataTable.vue"),
    DashboardFaturamentoVisaoMunicipiosKpisMap: () => import("@/components/dashboardFaturamento/DashboardFaturamentoVisaoMunicipiosKpisMap.vue"),
    DashboardFaturamentoVisaoMunicipiosCardsKpisPorMunicipio: () => import("@/components/dashboardFaturamento/DashboardFaturamentoVisaoMunicipiosCardsKpisPorMunicipio.vue"),
  },
  props: {
    competencia: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    municipios: {
      type: Array,
      required: true,
    },
    municipioSelecionado: {
      type: String,
      required: true,
    },
    tipoOrdenacao: {
      type: String,
      required: true,
    },
    kpiOrdenacao: {
      type: String,
      required: true,
    },
    activatedTab: {
      type: Number,
      required: true,
    },
    traducaoIndicadores: {
      type: Object,
      default: {}
    },
    regionalSelecionada: {
      type: Number,
      required:true
    }
  },
  data: () => ({
    listaKpisCardInicial: [
      "qrt",
      "prp",
      "psl",
      "prf",
      "pcf"
    ],
    dadosKpis: [],
    dadosKpisCompetenciaAnterior: [],
    dadosKpisTotalizados: {},
    dadosKpisTotalizadosCompetenciaAnterior: {},
    metasKpis: {},
    exportingOptions:{
      enabled: true,
        buttons: {
            contextButton: {
              align: 'right',
              verticalAlign: 'bottom',
              menuItems: [
                    {
                        textKey: 'downloadPNG',
                        text: 'Baixar como PNG',
                        onclick: function () {
                            this.exportChart();
                        }
                    },
                    {
                        separator: true
                    },
                    {
                        textKey: 'downloadCSV',
                        text: 'Baixar como CSV',
                        onclick: function () {
                            this.downloadCSV();
                        }
                    },
                    {
                        textKey: 'downloadXLS',
                        text: 'Baixar como XLS',
                        onclick: function () {
                            this.downloadXLS();
                        }
                    }
              ]
            }
        }
    }
  }),
  computed: {
    dadosKpisMunicipioSelecionado() {
      if(this.municipioSelecionado){
        let index = this.dadosKpis.findIndex(dados => dados.nome_municipio == this.municipioSelecionado);
  
        return index == -1 
          ? null 
          : this.dadosKpis[index];
      }
      return this.dadosKpisTotalizados
    },
    dadosKpisMunicipioSelecionadoCompetenciaAnterior() {
      if(this.municipioSelecionado){
        let index = this.dadosKpisCompetenciaAnterior.findIndex(dados => dados.nome_municipio == this.municipioSelecionado);
  
        return index == -1 
          ? null 
          : this.dadosKpisCompetenciaAnterior[index];
      }
      return this.dadosKpisTotalizadosCompetenciaAnterior
    },
  },
  mounted() {
    this.getDados();
  },
  methods: {
    getDados() {
      // Apenas atualiza os dados se for a visao selecionada
      if (this.activatedTab !== 2) return; 

      this.$emit("updateLoadingDados", true);

      Promise.all([
        this.getKpisByMunicipio(),
        this.getKpisByMunicipioCompetenciaAnterior(),
        this.getMetasKpisMunicipios(),
        this.getDadosKpisTotalizados(),
        this.getDadosKpisTotalizadosCompetenciaAnterior(),
      ])
        .catch(err => {
          console.error(err);
          this.$toast.error('Erro recuperar dados.', '', { position: 'topRight' });
        })
        .finally(() => this.$emit("updateLoadingDados", false));
    },
    getDadosKpisTotalizados() {
      return DashboardFaturamentoService.getKpisTotalizados(
        this.competencia,
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosKpisTotalizados = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosKpisTotalizadosCompetenciaAnterior() {
      return DashboardFaturamentoService.getKpisTotalizados(
        dayjs(this.competencia).subtract(1, 'month').format('YYYY-MM'), 
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosKpisTotalizadosCompetenciaAnterior = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getKpisByMunicipio() {
      return DashboardFaturamentoService.getKpisByMunicipio(
        this.competencia,
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosKpis = res;
        })
        .catch(err => {
          throw err;
        })
    },
    getKpisByMunicipioCompetenciaAnterior() {
      return DashboardFaturamentoService.getKpisByMunicipio(
        dayjs(this.competencia).subtract(1, 'month').format('YYYY-MM'),
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosKpisCompetenciaAnterior = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getMetasKpisMunicipios() {
      return DashboardFaturamentoService.getMetasKpisMunicipios(
        this.competencia,
        this.regionalSelecionada
      )
        .then(res => {
          this.metasKpis = res;
        })
        .catch(err => {
          throw err;
        })
    },
  },
  watch: {
    activatedTab() {
      this.getDados();
    },
    competencia() {
      this.getDados();
    },
    regionalSelecionada(){
      this.getDados();
    }
  }
}

</script>

<style>

</style>